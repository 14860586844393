import { api } from '.';

export function addMerchantType(payload) {
  return api('/v1/merchantTypes/addMerchantType', payload);
}

export function getMerchantTypes(payload) {
  payload = { page: 1, limit: 20, ...payload };

  return api('/v1/merchantTypes/getMerchantTypes', payload);
}

export function getMerchantType(payload) {
  return api('/v1/merchantTypes/getMerchantType');
}

export function editMerchantType(payload) {
  return api('/v1/merchantTypes/editMerchantType', payload);
}

export function removeMerchantType({ id }) {
  return api('/v1/merchantTypes/removeMerchantType', { id });
}
