<template>
  <!-- eslint-disable  -->
  <div>
    <v-card flat class="w100p"></v-card>
    <div class="d-sm-flex mb-3 text-Heading6 Medium d-none">
      {{ $store.getters['app/getRouteTitle'] }}
    </div>

    <FilterBox
      :open.sync="isFilterOpen"
      v-model="filter"
      :loading="isLoading"
      :options="filterOptions"
      @search="fetchData"
    />

    <SizeBox height="24" />

    <v-card
      outlined
      :color="$vuetify.breakpoint.smAndDown ? 'transparent' : null"
    >
      <v-card-text class="d-flex align-center pb-0 pb-sm-4">
        <v-spacer></v-spacer>
        <RefreshBtn :fetchData="fetchData" :loading="isLoading" />

        <SizeBox width="14" />
        <ExportBtn
          :items="result.list"
          :headers="headers"
          :file-name="`${
            $store.getters['app/getRouteTitle']
          } ${$moment().format('YYYY-MM-DD_H:mm:ss')}`"
          sheet-name="sheetName"
          :disabled="!$getGrantValue('agent_accounting_reports.export')"
        />
      </v-card-text>
      <DataTable
        :loading="isLoading"
        :list="result.list"
        :total="result.total"
        :fields="headers"
        :replace="['merchant_type']"
        @get-list="fetchData"
      >
        <template #item.merchant_type="{ item }">
          <v-chip :color="parseTypeColor(item.merchant_type)" small>
            {{ item.merchant_type }}
          </v-chip>
        </template>
      </DataTable>
    </v-card>
    <FunctionFab
      @info="isInfoOpen = true"
      @refresh="fetchData()"
      @open-filter="isFilterOpen = true"
      :no-filter="!filterOptions.length"
      :no-info="!Object.entries(result.header_info).length"
    />
  </div>
</template>


<script>
import { getAgentAccountingReports } from '@/api/statistics';
import { getMerchantTypes } from '@/api/merchantType';

export default {
  data() {
    return {
      ...this.$clone(this.$commonSchema),
      headers: [
        {
          text: '商戶類別',
          value: 'merchant_type',
          align: 'center',
          width: '104px'
        },
        {
          text: '代理商名稱',
          value: 'merchant_name',
          align: 'center',
          width: '104px'
        },
        {
          text: '資金總額',
          value: 'capital_amt',
          align: 'center',
          width: '120px'
        },
        {
          text: '提交訂單',
          value: 'total_order',
          align: 'center',
          width: '104px'
        },
        {
          text: '已付訂單',
          value: 'total_paid_collect_order',
          align: 'center',
          width: '104px'
        },
        {
          text: '未付訂單',
          value: 'total_unpaid_collect_order',
          align: 'center',
          width: '104px'
        },
        {
          text: '提交金額',
          value: 'total_collect_order_amt',
          align: 'center',
          width: '104px'
        },
        {
          text: '用戶入帳',
          value: 'total_collect_order_credit_amt',
          align: 'center',
          width: '104px'
        },
        {
          text: '代收手續費',
          value: 'total_collect_order_fee',
          align: 'center',
          width: '104px'
        },
        {
          text: '凍結資金',
          value: 'frozen_amt',
          align: 'center',
          width: '104px'
        },
        {
          text: '代付總額',
          value: 'total_pay_order_amt',
          align: 'center',
          width: '104px'
        },
        {
          text: '代付手續費',
          value: 'total_pay_order_fee',
          align: 'center',
          width: '104px'
        },
        {
          text: '到帳金額',
          value: 'total_pay_order_actual_amt',
          align: 'center',
          width: '104px'
        }
      ],
      filterOptions: [
        {
          label: '建立時間',
          type: 'timeSet',
          name: 'created_at',
          range: true,
          withTime: true
        },
        {
          label: '成功時間',
          type: 'timeSet',
          name: 'succeeded_at',
          range: true,
          withTime: true
        },
        {
          label: '代理商名稱',
          type: 'text',
          name: 'query.name'
        },
        {
          label: '代理商類別',
          type: 'autocomplete',
          name: 'merchant_type_id',
          api: getMerchantTypes
        }
      ]
    };
  },

  async created() {
    this.fetchData();
  },

  methods: {
    async fetchData(condition = this.condition) {
      this.isLoading = true;
      this.condition = condition;

      const Data = await getAgentAccountingReports({
        ...this.filter,
        ...condition
      });
      if (!Data.error) {
        this.result.total = Data.total;
        this.result.page = Data.page;
        this.$set(
          this.result,
          'list',
          Data.items.map((item) => ({
            ...item
          }))
        );
      }
      this.isLoading = false;
    },
    parseTypeColor(merchant_type_code) {
      switch (merchant_type_code) {
        case '第一級代理商':
          return 'Alert300';
        case '第二級代理商':
          return 'Error200';
        case '第三級代理商':
          return 'Primary100';

        default:
          return 'Secondary100';
      }
    }
  }
};
</script>

<style lang="scss" scoped>
//
</style>
